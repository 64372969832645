import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

class ImageFeaturedListingProperty extends React.Component {

  setStatusColor(){
    if(this.props.property.state.toLowerCase() === "For_sale".toLowerCase()){
      return "sale-notic";
    }
    else if(this.props.property.state.toLowerCase() === "Sold".toLowerCase()){
      return "sold-notic";
    }
    else{
      return "rent-notic";
    }
  }

  render(){
    var main_image_url = "";
    var main_image = 0;
    if(this.props.property.Main_Image > 0){
      main_image =  this.props.property.Main_Image - 1;
    }
    if (this.props.property.images.length > 0) {
      main_image_url = this.props.property.images[main_image].url.replace("https://azlivesavisaverde.blob.core.windows.net","https://visaverde-images.azureedge.net");
    }
    return (
        <div className="feature-pic">   
            <div className={this.setStatusColor()}>
              {this.props.intl.formatMessage({ id:'state-'+ this.props.property.state})}
            </div>
            <img 
                style={{
                  height: 200,
                  objectFit:"contain"
                }}
                src={main_image_url}/> 
        </div>
      )}}
  
ImageFeaturedListingProperty.propTypes = {
    property: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired
}
  
export default ImageFeaturedListingProperty
