import Select from 'react-select';
import React from "react"

export function  CustomReactSelect({value,options,onChange,placeholder,name, isMulti}){

    const customStyles = {
        option: styles => ({
            ...styles,
            fontSize: 15,
        }),
        control: styles => ({
            ...styles,
          fontSize: 15,
          background: "white",
          minHeight: 54
        }),
        indicatorsContainer: styles => ({
          ...styles
        }),
        input: styles => ({
          ...styles,
          margin: 0,
          padding: 0
        }),
        placeholder: styles => ({
          ...styles,
          color: "black"
        })
      }
      
    return ( <Select
            name={name}
            placeholder={placeholder}
            value={value && value.split(",").map(v => ({
                value: v, 
                label: options.find(o => o.value.toString() === v.toString()).label
            }))}
            isMulti={isMulti}
            hideSelectedOptions={false}
            isSearchable={true}
            onChange={onChange}
            options={options}
            styles={customStyles}
            isClearable={true}
        />
    )
}

export default CustomReactSelect