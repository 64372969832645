import React, { useRef, useState, useEffect }  from "react"
import { StaticQuery, graphql } from "gatsby"
import "./SearchPropertyFilter.css"
import CustomReactSelect from './CustomReactSelect'
import { useIntl} from "gatsby-plugin-intl"

import propertiesData from "../../../../build_data/properties.json"

const queryString = require('query-string');
const initialShowed = 18;


export function  SearchPropertyFilter({parentCallback}){
    const intl = useIntl()
    const [searchObject, setSearchObject] = useState({});
    const [id, setId] = useState("");
    const [propertyType, setPropertyType] = useState(null);
    const [municipality, setMunicipality] = useState(null);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [zone, setZone] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [complex, setComplex] = useState(null);
    const [extras, setExtras] = useState(null);
    const isFirst = useRef(true);

    useEffect(() => {
        if (!isFirst.current && window.location.href.indexOf("property-sales") !== -1) {
            searchAction();
            //needed when you click on back buttons
            window.onpopstate  = (e) => {
                var parsed = buildSearchObject();
                search(parsed);
            }
        }
        else if (!isFirst.current && window.location.href.indexOf("property-sales") === -1){
            const stringified = queryString.stringify(searchObject);
            //fix for support query string parameters. We had issues with google engine
            //Example: http://localhost:8000/es?gclid=Cj0KCQjw
            // this generated issues when use the search box
            window.location.href = window.location.href.replace(window.location.search,"") + "property-sales/"+ window.location.search + "#" + stringified;
        }
        else if (isFirst.current && window.location.href.indexOf("property-sales") !== -1) {
            const parsed = buildSearchObject();
            setSearchObject(parsed);
        }

        //different behaviors, needed for listen the searchObject
        isFirst.current = false;

    }, [searchObject]);
    
    function buildSearchObject(){
        //build initial search object 
        const parsed = queryString.parse(window.location.hash);
        //init properties
        setId(parsed.id !== undefined ? parsed.id : "");
        setPropertyType(parsed.propertyType!== undefined ? parsed.propertyType : null);
        setMunicipality(parsed.municipality!== undefined ? parsed.municipality : null);
        setMinPrice(parsed.minPrice !== undefined ? parsed.minPrice : null);
        setMaxPrice(parsed.maxPrice !== undefined ? parsed.maxPrice : null);
        setZone(parsed.zone!== undefined ? parsed.zone : null);
        setRooms(parsed.rooms === undefined ? null : parsed.rooms);
        setComplex(parsed.complex === undefined ? null : parsed.complex);
        setExtras(parsed.extras === undefined ? null : parsed.extras);
        
        //default optional values
        if (parsed.show === undefined){
            parsed.show = initialShowed;
        }

        return parsed;
    }


    function searchAction(){
        //generate query string
        const stringified = queryString.stringify(searchObject);
        
        if(window.history.pushState) {
            window.history.pushState(null, null, "#" + stringified);
        }
        else {
            window.location.hash = stringified;
        }

        search(queryString.parse(stringified));
    }

    function generatePrices(properties, isMin){
        var prices = properties.map(p => p.price);
        //var min = Math.min( ...prices );
        var max = Math.max( ...prices );

        //First group
        var from = 50000;
        var to = 1000000;
        var result = [];
        var step = 50000;
        while (from <= to) {
            if(max >= from){
                result.push({
                    label: from + " €",
                    value: from
                });
            }
            else{
                break;
            }
            from=from+step;
        } 
        //does mean that the max property is minor than the to limit and we does not include the upper bound in the list      
        from=from+step;
        if(from < to){
            result.push({
                label: from + " €",
                value: from
            }); 
        }

        //Other group
        if(1500000 <= max){
            result.push({
                label: 1500000 + " €",
                value: 1500000
            }); 
        }

        //Other group
        if(2000000 <= max){
            result.push({
                label: 2000000 + " €",
                value: 2000000
            }); 
        }

        //Other group
        if (!isMin) {
            if(2000000 < max){
                result.push({
                    label:  "+2000000 €",
                    value: 999999999999999
                }); 
            }
        }
        return result;
    }

    function search(searchObject){
        //const parsed = queryString.parse(window.location.hash);
        parentCallback(searchObject);
    }

    function handleInputChange(event){
        //set the new search object
        var data = JSON.parse(JSON.stringify(searchObject));
        var name = event.target.name;
        var value = event.target.value;
        if(event.target.value !== null && event.target.value.length > 0){
            data[name] = value
        }
        else{
            delete data[name];
        }

        //reset number of properties shown 
        data.show = initialShowed;

        //update the zones because depend from Municipality
        if (name === "municipality"){
            setZone(null); 
            delete data["zone"];
            setMunicipality(value);
        }
        else if (name === "zone"){
            setZone(value); 
        } 
        else if (name === "rooms"){
            setRooms(value);
        } 
        else if (name === "propertyType"){
            setPropertyType(value);
        } 
        else if (name === "complex"){
            setComplex(value);
        } 
        else if (name === "extras"){
            setExtras(value);
        } 
        else if (name === "id"){
            setId(value);
        } 
        else if (name === "minPrice"){
            setMinPrice(value);
        } 
        else if (name === "maxPrice"){
            setMaxPrice(value);
        } 
        setSearchObject(data);
    }

    function handleRoomChange(valueObject){
        if (valueObject === null || valueObject.length === 0) valueObject = null;
        var event = {
            target: {
                name: "rooms",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }

    function handleMunicipalityChange(valueObject){
        if (valueObject === null || valueObject.length === 0) valueObject = null;
        var event = {
            target: {
                name: "municipality",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }

    function handleZoneChange(valueObject){
        if (valueObject === null || valueObject.length === 0) valueObject = null;
        var event = {
            target: {
                name: "zone",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }

    function handlePropertyTypeChange(valueObject){
        if (valueObject === null || valueObject.length === 0) valueObject = null;
        var event = {
            target: {
                name: "propertyType",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }  

    function handleComplexChange(valueObject){
        if (valueObject === null || valueObject.length === 0) valueObject = null;
        var event = {
            target: {
                name: "complex",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }
    
    function handleExtrasChange(valueObject){
        if (valueObject === null || valueObject.length === 0) valueObject = null;
        var event = {
            target: {
                name: "extras",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }

    function handleRefChange(valueObject){
        if (valueObject === null || valueObject.length === 0){
            valueObject = null;
        }
        else{
            valueObject = [valueObject]; //convert to array, generic customreactselect
        }
        var event = {
            target: {
                name: "id",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }

    function handleMinPriceChange(valueObject){
        if (valueObject === null || valueObject.length === 0){
            valueObject = null;
        }
        else{
            valueObject = [valueObject]; //convert to array, generic customreactselect
        }
        var event = {
            target: {
                name: "minPrice",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }


    function handleMaxPriceChange(valueObject){
        if (valueObject === null || valueObject.length === 0){ 
            valueObject = null;
        }
        else{
            valueObject = [valueObject]; //convert to array, generic customreactselect
        }
        var event = {
            target: {
                name: "maxPrice",
                value: valueObject !== null ? valueObject.map(r => r.value).join() : null
            }
        };
        handleInputChange(event);
    }


    return (
            <StaticQuery
                query={graphql`
                query {
                        placeholderImage: file(relativePath: { eq: "bg.jpg" }) {
                            childImageSharp {
                                fluid(maxWidth: 300) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                     }
                `}
                render={data => 
                    <div className="filter-search">
                            <div className="container">
                                <div className="filter-form">
                                    <div className="row filter-title-row">
                                        <h3 className="filter-title">{intl.formatMessage({ id:'Property-Search'}) }</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            {/* <input id="search-box-property-id-input" value={id} type="number" placeholder={intl.formatMessage({ id:'Insert-Reference-No'})} name="id"  onChange={handleInputChange}></input> */}
                                            <CustomReactSelect                                        
                                                name="id"
                                                placeholder={intl.formatMessage({ id:'Insert-Reference-No'}) }
                                                value={id}
                                                isMulti={false}
                                                onChange={handleRefChange}
                                                options={propertiesData.filter(p => p.published)
                                                    .map(p => p.id)
                                                    .filter((item,index,array) => array.indexOf(item) === index)
                                                    .sort()
                                                    .map(id => ({
                                                       value: id,
                                                       label: id
                                                    }))
                                                }
                                            >
                                            </CustomReactSelect>
                                        </div>
                                        <div className="col-md-4  col-sm-12">
                                            <CustomReactSelect                                        
                                                name="propertyType"
                                                placeholder={intl.formatMessage({ id:'Select-property-type'})}
                                                value={propertyType}
                                                isMulti={true}
                                                onChange={handlePropertyTypeChange}
                                                options={[
                                                    { value: 'Apartments', label: intl.formatMessage({ id:'type-Apartments'})},
                                                    { value: 'Building_land', label:intl.formatMessage({ id:'type-Building_land'})},
                                                    { value: 'Business', label:intl.formatMessage({ id:'type-Business'})},
                                                    { value: 'Canarian_fincas', label:intl.formatMessage({ id:'type-Canarian_fincas'})},
                                                    { value: 'Garage_Storeroom', label:intl.formatMessage({ id:'type-Garage_Storeroom'})},
                                                    { value: 'Local', label: intl.formatMessage({ id:'type-Local'})},
                                                    { value: 'New_development', label:intl.formatMessage({ id:'type-New_development'})},
                                                    { value: 'Town_Houses', label:intl.formatMessage({ id:'type-Town_Houses'})},
                                                    { value: 'Villas_Bungalows', label: intl.formatMessage({ id:'type-Villas_Bungalows'})},
                                                ]}
                                            >
                                            </CustomReactSelect>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <CustomReactSelect                                        
                                                name="rooms"
                                                placeholder={intl.formatMessage({ id:'Select-No-bedrooms'})}
                                                value={rooms}
                                                isMulti={true}
                                                onChange={handleRoomChange}
                                                options={[
                                                    { value: '0', label: '0' },
                                                    { value: '1', label: '1' },
                                                    { value: '2', label: '2' },
                                                    { value: '3', label: '3' },
                                                    { value: '4', label: '4' },
                                                    { value: '5', label: '5' },
                                                    { value: '6', label: '6' }
                                                ]}
                                            >
                                            </CustomReactSelect>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 alignEnd">
                                            <CustomReactSelect                                        
                                                name="municipality"
                                                placeholder={intl.formatMessage({ id:'Select-municipality'}) }
                                                value={municipality}
                                                isMulti={true}
                                                onChange={handleMunicipalityChange}
                                                options={propertiesData.filter(p => p.published)
                                                    .map(p => p.municipality)
                                                    .filter((item,index,array) => array.indexOf(item) === index)
                                                    .sort()
                                                    .map(municipality => ({
                                                       value: municipality,
                                                       label: intl.formatMessage({ id:'municipality.'+ municipality})
                                                    }))
                                                }
                                            >
                                            </CustomReactSelect>
                                        </div>
                                        <div className="col-md-4 col-sm-12 alignStart">
                                            <CustomReactSelect                                        
                                                name="zone"
                                                placeholder={intl.formatMessage({ id:'Select-zone'})}
                                                value={zone}
                                                isMulti={true}
                                                onChange={handleZoneChange}
                                                options={propertiesData.filter(p => p.published)
                                                    .filter(p => municipality === null || municipality.indexOf(p.municipality) !== -1)
                                                    .filter(p => p.Zone !== "Select_Zone")
                                                    .map(p => p.Zone)
                                                    .filter((item,index,array) => array.indexOf(item) === index)
                                                    .sort()
                                                    .map(zone => ({
                                                       value: zone,
                                                       label: intl.formatMessage({ id:'zones.'+ zone})
                                                    }))
                                                }
                                            >
                                            </CustomReactSelect>
                                        </div>
                                        <div className="col-md-4 col-sm-12 alignStart">
                                            <CustomReactSelect                                        
                                                name="complex"
                                                placeholder={intl.formatMessage({ id:'Select-complex'}) }
                                                value={complex}
                                                isMulti={true}
                                                onChange={handleComplexChange}
                                                options={propertiesData.filter(p => p.published)
                                                    .filter(p => p.complex !== null)
                                                    .map(p => p.complex)
                                                    .filter((item,index,array) => array.indexOf(item) === index)
                                                    .sort()
                                                    .map(cc => ({
                                                       value: cc,
                                                       label: cc
                                                    }))
                                                }
                                            >
                                            </CustomReactSelect>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 alignEnd">
                                            <CustomReactSelect                                        
                                                name="minPrice"
                                                placeholder={intl.formatMessage({ id:'Minimum-price'}) }
                                                value={minPrice}
                                                isMulti={false}
                                                onChange={handleMinPriceChange}
                                                options={generatePrices(propertiesData.filter(p => p.published), true)}
                                            >
                                            </CustomReactSelect>
                                        </div>
                                        <div className="col-md-4 col-sm-12 alignStart">
                                            <CustomReactSelect                                        
                                                name="maxPrice"
                                                placeholder={intl.formatMessage({ id:'Maximum-price'}) }
                                                value={maxPrice}
                                                isMulti={false}
                                                onChange={handleMaxPriceChange}
                                                options={generatePrices(propertiesData.filter(p => p.published), false)}
                                            >
                                            </CustomReactSelect>
                                        </div>
                                        <div className="col-md-4 col-sm-12 alignStart">
                                        <CustomReactSelect                                        
                                                name="extras"
                                                placeholder={intl.formatMessage({ id:'extras'})}
                                                value={extras}
                                                isMulti={true}
                                                onChange={handleExtrasChange}
                                                options={[
                                                    { value: 'Coast', label: intl.formatMessage({ id:'Coast'}) },
                                                    { value: 'is_featured', label: intl.formatMessage({ id:'is_featured'}) },
                                                    { value: 'garage', label: intl.formatMessage({ id:'garage'}) },
                                                    { value: 'Golf', label: intl.formatMessage({ id:'Golf'}) },
                                                    { value: 'is_investment', label: intl.formatMessage({ id:'Investments'}) },
                                                    { value: 'is_luxury', label: intl.formatMessage({ id:'Luxury-properties'}) },
                                                    { value: 'Mountain', label: intl.formatMessage({ id:'Mountain'}) },
                                                    { value: 'new_development', label: intl.formatMessage({ id:'type-New_development'}) },
                                                    { value: 'pool', label: intl.formatMessage({ id:'Pool'}) },
                                                    { value: 'is_re_sale', label: intl.formatMessage({ id:'Re-sale'}) },
                                                    { value: 'Town', label: intl.formatMessage({ id:'Town'}) },
                                                ]}
                                            >
                                            </CustomReactSelect>
                                        </div>
                                    </div>
                                    <div className="row alignCenter">
                                        <div className="col-md-12 col-sm-12">
                                            {/* <button className="site-btn fs-submit button-demo" onClick={searchAction.bind(this)}>{('Search - ADD TRANSLATION')}</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            />
        )}

export default SearchPropertyFilter

