import React from "react"
import { Link } from "gatsby-plugin-intl";
import ImageFeaturedListingProperty from './ImageFeaturedListingProperty';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';



class FeaturedListingProperty extends React.Component {

    getUpdateDay(date){
        //var difference = parseInt(new Date(((new Date()) - (date))) / (1000 * 60 * 60 * 24));
        //disabled because its a static website generator. Research how to allow execute this code in real time
        /*if (difference === 0){
            return "Today"
        }
        else{
            return difference + " days ago";
        }*/
        return (new Date(date)).toLocaleDateString();
    };

    formatPrice(price){
        var formatter = new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
          });
        return formatter.format(price);  
    };


    render(){

        return (
            <div className="col-lg-4 col-md-6" key={this.props.property.id}>
            <ScrollAnimation animateIn='fadeIn'>

                <div className="feature-item">
                    <Link to={"/property/" + this.props.property.id} onClick={this.props.onClickCallback} >
                        <ImageFeaturedListingProperty property={this.props.property} intl={this.props.intl}></ImageFeaturedListingProperty>
                        <div className="feature-text">
                            <div className="feature-text-margin">
                                <div></div>
                            </div>
                            <div className="text-center feature-title">
                                <h5 style={{visibility: (this.props.property.Zone != "Select_Zone") ? "visible" : "hidden"}}>{this.props.intl.formatMessage({ id:'zones.' + this.props.property.Zone})}</h5>
                                <p><i className="fa fa-map-marker"></i> {this.props.intl.formatMessage({ id:'municipality.'+ this.props.property.municipality})}</p>
                            </div>
                            <div className="room-info-warp">
                                <div className="room-info room-info-top">
                                    <div className="room-info-row">
                                        <p><i className="fa fa-bed"></i> {this.props.property.bedrooms} {this.props.intl.formatMessage({ id:'Bedrooms' })}</p>
                                        <p><i className="fa fa-bath"></i> {this.props.property.bathrooms} {this.props.intl.formatMessage({ id:'Bathrooms' })}</p>
                                    </div>
                                    <div className="room-info-row">
                                        <p>
                                            {this.props.intl.formatMessage({ id:'type-'+ this.props.property.type})} 
                                        </p>
                                        <p>
                                            ref: {this.props.property.id}
                                        </p>
                                    </div>  
                                </div>
                            </div>
                            <span className="room-price">{this.formatPrice(this.props.property.price)}</span>
                        </div>
                    </Link>
                </div>
              </ScrollAnimation>
            </div>
        )}
    }

export default FeaturedListingProperty

FeaturedListingProperty.propTypes = {
    property: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
}


